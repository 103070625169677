/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
//import * as ActiveStorage from "@rails/activestorage"
//import "channels"

import "@fortawesome/fontawesome-free"

// Rails.start()
Turbolinks.start()
//ActiveStorage.start()

import './console'
import "bootstrap"
// import "./lib/jquery.sortElements"
// import "jshashtable"
// import "jquery-numberformatter"
// import "underscore.string"
// import "./underscore_settings"
// import "./lib/mixins"
// import "backbone"
// import "backbone.paginator"
// import "backgrid"
// import "backgrid-paginator"
// import "i18n-js"
// import "./i18n/translations"
// import "./app"
// import "./settings"
// import "./trackables"
import "watermark/dist/jquery.watermark"
import "./plugins/jquery.stickytableheaders"
import "./functions"
import "./out_docs"
import "./in_docs"
import "./custom_bootstrap"
import "./events_always"
import "./events_once"
import "./dashboard"
import "./orders"
import "./infinite"
import "./sticky_tables"

